import React from 'react';
import PropTypes from 'prop-types';
import './Attributions.scss';

const Attributions = ({activeLayers, countryPage}) => {
    const attributions = {
        base: <a href='http://www.openstreetmap.org/about/' target='_blank' rel="noopener noreferrer">
            © OpenStreetMap</a>,
        pdc: '© PDC/Active Hazards Map Service',
        acled: <>Armed Conflict Location &amp;<br/>Event Data Project (ACLED) <a
            href='https://acleddata.com' target='_blank' rel="noopener noreferrer">acleddata.com</a></>,
        undernourishment: <>Source: FAO, IFAD, UNICEF, WFP and WHO. 2019.<br/>
            <a href='http://www.fao.org/state-of-food-security-nutrition/en/'
               target='_blank' rel="noopener noreferrer">The State of Food Security and Nutrition in the World
                2019</a>.</>,
        fcs: 'Source: WFP',
        ndvi: '© WFP-VAM, MODIS/NASA',
        rainfall: '© WFP-VAM, CHIRPS/UCSB',
        ipc: <>Data source: Integrated Food Security Phase Classification (IPC) /<br/>
            Cadre Harmonisé (CH)</>,
        covid: <>Covid-19 data: © Johns Hopkins University</>
    };
    const attributionItems = [];
    activeLayers.forEach(l => attributionItems.push(attributions[l], ', '));
    if (countryPage) {
        attributionItems.push(<br/>, attributions['covid']);
    }
    attributionItems.push(<br/>, attributions['base']);
    return <div className='mapAttribution'>{attributionItems}</div>;
};

Attributions.propTypes = {
    activeLayer: PropTypes.shape({
        base: PropTypes.string,
        scatter: PropTypes.string
    }),
    countryPage: PropTypes.bool
};
Attributions.defaultProps = {};

export default Attributions;