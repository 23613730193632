const domain = 'hungermapdata.org'

const cdnPublicFile = path => `https://cdn.${domain}/${path}`;

const ossPublicFile = path => `https://static.${domain}/${path}`;
const imgFile = filename => ossPublicFile(`hungermap/img/${filename}`);

const legacyApiUrl = path => `https://api.${domain}/v2/${path}`

const apiUrl = path => `https://api.hungermapdata.org/${path}`;

const prodRainfallTilesApiUrl = path => `https://api.earthobservation.vam.wfp.org/${path}`

const lastNotNull = arr => arr.filter(d => d !== null).slice(-1)[0] || 0;

export {ossPublicFile, cdnPublicFile, apiUrl, legacyApiUrl, imgFile, lastNotNull, prodRainfallTilesApiUrl};